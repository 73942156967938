export const checkIfInSampleGroup = () => {
  const sampleKey = "TXG_FS_SAMPLE";
  const sampleDateKey = "TXG_FS_SAMPLE_DATE";
  let fsSampleValue = window.localStorage.getItem(sampleKey);
  let fsSampleDate = window.localStorage.getItem(sampleDateKey);

  if (
    !fsSampleValue ||
    !fsSampleDate ||
    Date.now() - Number(fsSampleDate) > 1000 * 60 * 60 * 24
  ) {
    fsSampleValue = String(Math.random());
    fsSampleDate = String(Date.now());
    window.localStorage.setItem(sampleKey, fsSampleValue);
    window.localStorage.setItem(sampleDateKey, fsSampleDate);
  }

  const fsSampleRate = 0.25;
  return parseFloat(fsSampleValue) < fsSampleRate;
};
