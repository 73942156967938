import type { Navigation_FullFragment } from "@10xdev/types/__generated__/graphql-types";
import { createContext } from "react";

import type { Props as BlogProps } from "../pages/blog";
import type { Props as CompanyProps } from "../pages/company";
import type { Props as ServiceProviderSignupProps } from "../pages/service-providers/signup";
import availableLocales from "../utils/available-locales";

type PageStaticProps = CompanyProps["staticProps"] &
  BlogProps["staticProps"] &
  ServiceProviderSignupProps["staticProps"];

export type StaticProps = Partial<PageStaticProps> & {
  countryCode: string;
  navigation?: Navigation_FullFragment;
  showLanguageSelect: boolean;
};

const StaticPropsContext = createContext<StaticProps>({
  countryCode: availableLocales.defaultCountry,
  showLanguageSelect: false,
});

export default StaticPropsContext;
