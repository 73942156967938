type CookieYesKey =
  | "consent"
  | "action"
  | "necessary"
  | "functional"
  | "analytics"
  | "performance"
  | "advertisement"
  | "other";

export const checkConsentCookie = (name: CookieYesKey) => {
  const consentCookie = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith("cookieyes-consent="));

  if (!consentCookie) {
    return false;
  }

  const cookieMap = consentCookie.split(",").reduce((obj, pair) => {
    const [key, val] = pair.split(":");
    obj[key] = val;
    return obj;
  }, {} as Record<string, string>);

  return cookieMap[name] === "yes";
};
