const countries = ["cn", "en", "jp"];
const countryToLanguageMap = {
  cn: "zh",
  en: "en",
  jp: "ja",
  kr: "ko",
};
const defaultLanguage = "en";
const languages = ["en", "zh", "ja"];
const languageToCountryMap = {
  en: "en",
  ja: "jp",
  ko: "kr",
  zh: "cn",
};

const defaultCountry = Object.entries(countryToLanguageMap).find(
  ([, language]) => {
    return language === defaultLanguage;
  },
)[0];

module.exports = {
  countries,
  countryToLanguageMap,
  defaultCountry,
  defaultLanguage,
  languageToCountryMap,
  languages,
};
