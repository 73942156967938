import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

const AccordionSideNavContext = createContext<{
  activeSubNavTitles: Record<string, boolean>;
  toggle: (subNavTitle: string) => void;
}>({
  activeSubNavTitles: {},
  toggle: () => {},
});

export function Provider({ children }: { children: ReactNode }) {
  const [activeSubNavTitles, setActiveSubNavTitles] = useState<
    Record<string, boolean>
  >({});

  return (
    <AccordionSideNavContext.Provider
      value={{
        activeSubNavTitles,
        toggle(subNavTitle) {
          setActiveSubNavTitles({
            ...activeSubNavTitles,
            [subNavTitle]: !activeSubNavTitles[subNavTitle],
          });
        },
      }}
    >
      {children}
    </AccordionSideNavContext.Provider>
  );
}

export function useAccordionContext() {
  return useContext(AccordionSideNavContext);
}
